import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  eventSelector,
  invitationsAcceptedCountSelector,
  invitationsCountSelector,
  messageCountSelector,
  isHostOrCohostSelector,
  isHostSelector,
  activeInvitationsSelector,
  processedPhotoCountSelector,
  processedVideoCountSelector
} from 'src/selectors'
import { changeEventsModal } from 'src/actions/event'
import SvgIcon from 'src/components/common/SvgIcon'
import { androidObject, isHobnobAndroidClient, pluralize } from 'services/utils'
import Button from 'components/common/Button'
import AddIconWhite from 'assets/icons/add-icon-white.png'
import { hasUserAccessToken } from 'services/access_tokens'
import cn from 'classnames'
import rightChevronSmall from 'src/assets/icons/right_chevron_small.svg'
import { showLogin } from 'src/actions/login'
import { Modal } from 'antd'

import styles from './guest-chat-photo-cell.scss'

const GuestChatPhotoCell = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const messageCount = useSelector(messageCountSelector)
  const invitationsCount = useSelector(invitationsCountSelector)
  const invitationsAcceptedCount = useSelector(invitationsAcceptedCountSelector)
  const photoCount = useSelector(processedPhotoCountSelector)
  const videoCount = useSelector(processedVideoCountSelector)
  const isHostOrCohost = useSelector(isHostOrCohostSelector)
  const isHost = useSelector(isHostSelector)
  const activeInvitations = useSelector(activeInvitationsSelector)
  const userId = useSelector(state => state.userId)
  const isLogin = hasUserAccessToken()

  const _messagePane = () => {
    let item = {}
    item.title = 'chat'
    item.text = 'Event Chat'
    item.key = 'chat'
    item.desc = `${messageCount} ${pluralize(messageCount, 'Message')}`

    item.isDisabled = !isLogin || event.state === 'draft'
    item.isEnabled = event.commenting_enabled

    if (!isLogin) {
      item.callback = () => {
        const params = {
          loginModalSubtitle: 'In order to display your data, we need to confirm your number'
        }
        dispatch(showLogin(params))
      }
    } else if (event.state === 'draft') {
      item.callback = () => {
        Modal.info({
          content: 'Chat will be enabled once your event is published.',
          centered: true
        })
      }
    }

    if (event) return item
  }

  const _guestsPane = () => {
    let item = {}
    item.title = 'guests'
    item.text = 'Guest List'
    item.key = 'guests'

    if (!event.guest_list_enabled) {
      item.text += ' (hidden)'
    }

    item.desc = `${invitationsCount} Invited`
    if (invitationsAcceptedCount) {
      item.desc += `, ${invitationsAcceptedCount} Attending`
    }

    const unSendCount = activeInvitations.filter(
      invitation => invitation.delivery_state === 'pending'
    ).length
    if (unSendCount) {
      item.desc += `, ${unSendCount} Unsent`
    }

    item.isEnabled = event.guest_list_enabled || isHostOrCohost

    return item
  }

  const _photosPane = () => {
    let item = {}
    item.title = 'Photos'
    item.text = 'Media Album'
    item.key = 'media'
    item.desc = `${photoCount} ${pluralize(photoCount, 'Photo')}, ${videoCount} ${pluralize(
      videoCount,
      'Video'
    )}`

    item.isDisabled = event.state === 'draft'
    if (event.state === 'draft') {
      item.callback = () => {
        Modal.info({
          content: 'Photos will be enabled once your event is published.',
          centered: true
        })
      }
    }
    item.isEnabled = true

    return item
  }

  const _renderLabel = panes => {
    let titleList = []
    for (let i = 0; i < panes.length; i++) {
      titleList.push(panes[i].title)
    }
    let str = titleList.join(', ').replace(/, ([^,]*)$/, ' & $1')

    return str
  }

  const onClickCell = (e, pane) => {
    if (isHobnobAndroidClient()) {
      if (pane.key === 'chat') {
        androidObject().launchEventChatActivity(event.id)
      } else if (pane.key === 'media') {
        androidObject().launchPhotosActivity(event.id, false, false)
      } else {
        dispatch(changeEventsModal(pane.key))
        window.scrollTo(0, 0)
      }

      return
    }

    if (pane.callback) {
      pane.callback()
    } else {
      dispatch(changeEventsModal(pane.key))
      window.scrollTo(0, 0)
    }
  }

  const handleAddGuests = () => {
    window.mixpanel.track('ADD GUESTS Clicked', { event_id: event.id, user_id: userId })
    dispatch(changeEventsModal('addGuests'))
    window.scrollTo(0, 0)
  }
  console.log(111, event)
  const panes = [_guestsPane(), _messagePane(), _photosPane()]
  return (
    <div className={styles['container']}>
      {panes.length >= 1 ? <h3 className={styles['cell-title']}>{_renderLabel(panes)}</h3> : null}
      {isHostOrCohost && isLogin && (
        <div
          className={cn(styles['add-guest-button'], {
            [styles['mobile-hidden']]: isHost && isLogin
          })}
        >
          <Button className={styles['button']} onClick={handleAddGuests}>
            <img src={AddIconWhite} width="16" />
            Add Guests
          </Button>
        </div>
      )}

      <ul>
        {panes.map(pane => {
          if (!pane.isEnabled) return

          return (
            <li
              className={cn(styles['cell-item'], pane.isDisabled && styles['cell-item-disabled'])}
              key={pane.key}
              onClick={event => onClickCell(event, pane)}
            >
              <div className={styles['pane-wrap']}>
                <div className={styles['pane-text']}>{pane.text}:</div>
                <div>{pane.desc}</div>
              </div>
              <SvgIcon icon={rightChevronSmall} className={styles['pane-chevron-icon']} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default GuestChatPhotoCell
